import EmptyLayout from "@/layout/EmptyLayer";

const BetaRouter = {
  path: "/beta",
  name: "Beta",
  redirect: "/beta/profile",
  component: EmptyLayout,
  children: [
    {
      path: "profile",
      name: "BetaProfile",
      component: () => import("@/views/Profile/BetaHome.vue"),
      meta: {
        user: true,
      },
    },
    {
      path: `/universities/`,
      name: "PartnersUniversities",
      component: () => import("@/views/Profile/PartnerUniversity.vue"),
    },
    {
      path: "project/:id",
      name: "BetaProjectView",
      component: () => import("@/views/Profile/BetaProjectView.vue"),
    },
    {
      path: "project/my",
      name: "BetaMyProject",
      component: () => import("@/views/Profile/BetaMyProject.vue"),
    },
    {
      path: "o/profile",
      name: "OrgProfile",
      component: () => import("@/views/Organization/Home.vue"),
    },
    {
      path: "start",
      name: "WelcomePage",
      component: () => import("@/views/BetaWelcomePage.vue"),
    },
    {
      path: "o/partner/invites",
      name: "PartnerInvites",
      component: () => import("@/views/Organization/OrgInviteLinks.vue"),
    },
    {
      path: `o/invited/`,
      name: "invite registration",
      component: () => import("@/views/Auth/UniqueLinkRegistration.vue"),
    },
    {
      path: `o/studentsList/`,
      name: "Get Students List",
      component: () => import("@/views/Organization/getStudentsList.vue"),
    },
    {
      path: `o/partners/list/`,
      name: "PartnersList",
      component: () => import("@/views/Organization/OrgPartners.vue"),
    },

    // Testing routes

    {
      path: "tests/:id/task/:level_id/:task_id",
      name: "TestingUser",
      component: () => import("@/views/TestingUnit/TestingUser.vue"),
    },
    {
      path: "tests/:id/task/:level_id/:task_id/:attempt_id",
      name: "TestingUserResult",
      component: () => import("@/views/TestingUnit/TestingUser.vue"),
    },
    // END Testing routes

    {
      path: "profile/achievements",
      name: "UserAchievements",
      component: () => import("@/views/Profile/Achievements.vue"),
    },
    {
      path: "octa-store",
      name: "OctaStore",
      component: () => import("@/views/Profile/Octastore.vue"),
    },
    {
      path: "inventory",
      name: "InventoryPage",
      component: () => import("@/views/Profile/Inventory.vue"),
    },
    {
      path: "news-list",
      name: "NewsList",
      component: () => import("@/views/Profile/NewsPage.vue"),
    },
    {
      path: "news-details",
      name: "NewsDetails",
      component: () => import("@/views/Profile/NewsDetail.vue"),
    },
    {
      path: "faq-page",
      name: "FAQPage",
      component: () => import("@/views/FAQ"),
    },
  ],
};

export default BetaRouter;
